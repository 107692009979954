<template>
  <v-container>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="経営理念" status="notInput"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <manegement-philosophy></manegement-philosophy>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="医院に関する情報" status="edit"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <company-profile></company-profile>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="ビジョン" status="done"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <company-vision></company-vision>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import VExpansionPanelHeaderWithLabel from '../../components/atomic/atoms/VExpansionPanelHeaderWithLabel.vue';

import ManegementPhilosophy from '../../components/atomic/organisms/bp/ManegementPhilosophy.vue'
import CompanyProfile from '../../components/atomic/organisms/bp/CompanyProfile.vue'
import CompanyVision from '../../components/atomic/organisms/bp/CompanyVision.vue'
export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    ManegementPhilosophy,
    CompanyProfile,
    CompanyVision
  },
}
</script>

<template>
  <div>

    <v-row>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="philosophy.question1"
          label="歯科医になった理由"
          :readonly="readonly"
          class="mb-5"
          reference="ManegementPhilosophy_question1.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="philosophy.question2"
          label="歯科医療で大切にしていること"
          :readonly="readonly"
          class="mb-5"
          reference="ManegementPhilosophy_question2.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="philosophy.question3"
          label="患者様に対して心がけていること"
          :readonly="readonly"
          class="mb-5"
          reference="ManegementPhilosophy_question3.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="philosophy.question4"
          label="これまで歯科治療してきた中で心に残っているエピソード"
          :readonly="readonly"
          class="mb-5"
          reference="ManegementPhilosophy_question4.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
    </v-row>  

    <v-card
      outlined
      elevation="5"
      class="mt-7 mb-7 customer-case-textarea"
    >
      <v-card-title class="align-end">
        <span class="mr-2">その他のお客様事例</span>
      </v-card-title>
      <v-row class="pl-5 pr-5">
        <v-col>
          <v-textarea
            outlined
            auto-grow
            label="事例1"
            v-model="philosophy.case1"
            :readonly="readonly"
            :clearable="!readonly"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
            outlined
            auto-grow
            label="事例2"
            v-model="philosophy.case2"
            :readonly="readonly"
            :clearable="!readonly"
          ></v-textarea>
        </v-col>
        <v-col>
          <v-textarea
            outlined
            auto-grow
            label="事例3"
            v-model="philosophy.case3"
            :readonly="readonly"
            :clearable="!readonly"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <v-textarea-with-link
        v-model="philosophy.finalyObjective"
        label="医院の目指している最終ゴール"
        :readonly="readonly"
        class="mb-5"
        reference="ManegementPhilosophy_finalyObjective.txt"
        :refDoc="true"
            ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="philosophy.brandmessage"
          label="ブランドメッセージ"
          :readonly="readonly"
          class="mb-5"
          reference="ManegementPhilosophy_brandmessage.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
    </v-row>

    <add-hearing-items v-model="philosophy.hearingAddItems" :readonly="readonly"></add-hearing-items>
  </div>
</template>

<script>
import VTextareaWithLink from '../../atoms/VTextareaWithLink.vue';
import InputSample from '../../atoms/InputSample.vue';
import AddHearingItems from '../../molecules/AddHearingItems.vue';


export default {
  components: {
    VTextareaWithLink,
    InputSample,
    AddHearingItems,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      philosophy: {
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,

        hearingAddItems: [
          {
            text: ''
          },
        ],
      },
    }
  },
  created(){
    if(this.value) {
      this.philosophy = this.value
    }
  },
  methods: {
    //経営理念セクションの未入力チェック
    isEmpty() {
      if(!this.philosophy) return true

      const philosophy = this.philosophy
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['question1', 'question2', 'question3', 'question4', 'case1', 'case2', 'case3', 'finalyObjective', 'brandmessage']
      ret = questionKeys.some(i => philosophy[i])
      if(ret) return false

      //追加質問項目をチェック
      if(philosophy.hearingAddItems && philosophy.hearingAddItems.length > 0) {
        ret = philosophy.hearingAddItems.some(i => i.label || i.text)
        if(ret) return false
      }

      return true
    },
  },
  watch: {
    value(val) {
      this.philosophy = val
    },
    "philosophy.inputStatusPhilosophy"(val) {
      this.$store.commit('brand_input_status_philosophy', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.customer-case-textarea{
  background-color: #f3faff!important;

  .input-sample{
    margin-bottom: -2px;
  }
}
</style>
<style lang="scss">
.customer-case-textarea{
  .v-input__slot{
    background-color: white!important;
  }
}
</style>
<template>
  <div class="pt-5 company-vision-wrap">

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="5" class="pr-5 mb-3">
        <div class="head-title">
          <span class="mr-2">ビジョン</span>
          <input-sample class="input-sample" :refDoc="true" reference="CompanyVision_vision.txt" label="ビジョン"></input-sample>
        </div>
        <div class="head-description">医院像やチームのイメージ、地域にどう関わっているか</div>
      </v-col>
      <v-col cols="3" class="pr-5 mb-3">
        <div class="head-title">
          <span class="mr-2">売上</span>
          <input-sample class="input-sample" :refDoc="true" reference="CompanyVision_performance.txt" label="売上"></input-sample>
        </div>
        <div class="head-description">売上や自費率・リピート率など</div>
      </v-col>
      <v-col cols="3">
        <div class="head-title">
          <span class="mr-2">組織・医院規模</span>
          <input-sample class="input-sample" :refDoc="true" reference="CompanyVision_organization.txt" label="組織・医院規模"></input-sample>
        </div>
        <div class="head-description">ユニット数やチーム体制・分院の予定</div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-0">
      <v-col cols="1" class="aline-center">
        <div>1年後</div>
      </v-col>
      <v-col cols="5" class="pr-5">
        <v-textarea v-model="visionItems.vision1" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3" class="pr-5">
        <v-textarea v-model="visionItems.performance1" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-textarea v-model="visionItems.organization1" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-0">
      <v-col cols="1" class="aline-center">
        <div>3年後</div>
      </v-col>
      <v-col cols="5" class="pr-5">
        <v-textarea v-model="visionItems.vision2" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3" class="pr-5">
        <v-textarea v-model="visionItems.performance2" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-textarea v-model="visionItems.organization2" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-0">
      <v-col cols="1" class="aline-center">
        <div>5年後</div>
      </v-col>
      <v-col cols="5" class="pr-5">
        <v-textarea v-model="visionItems.vision3" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3" class="pr-5">
        <v-textarea v-model="visionItems.performance3" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-textarea v-model="visionItems.organization3" :readonly="readonly" outlined auto-grow :clearable="!readonly"></v-textarea>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="1"></v-col>
      <v-col cols="5">
        <v-select
          v-model="visionItems.visionEvaluation"
          :items="visionEvaluation"
          item-text="visionEvaluation"
          item-value="visionEvaluation"
          label="ビジョン設計評価"
          outlined
          :clearable="!readonly"
          class="vision-evaluation"
          :readonly="readonly"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InputSample from '../../atoms/InputSample.vue';
import MasterData from '../../../../utils/MasterData'

export default {
  props: ['value', 'readonly'],
  components: {
    InputSample,
  },
  data () {
    return {
      visionEvaluation: [],
      visionItems: {
        vision1: null,
        vision2: null,
        vision3: null,
        performance1: null,
        performance2: null,
        performance3: null,
        organization1: null,
        organization2: null,
        organization3: null,
        visionEvaluation: null,
      }
    }
  },
  created(){
    if(this.value){
      this.visionItems = this.value
    }
  },
  methods: {
    //ビジョン情報セクションの未入力チェック
    isEmpty() {
      if(!this.visionItems) return true

      const vision = this.visionItems
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['vision1', 'vision2', 'vision3', 'performance1', 'performance2', 'performance3', 'organization1', 'organization2', 'organization3', 'visionEvaluation']
      ret = questionKeys.some(i => vision[i])
      if(ret) return false

      return true
    },

    clickReference_caseStudy(e) {
      this.$store.commit('open_referenceDocument', {top: e.clientY, left: e.clientX, id: 'aaaaaaa'})
    },
  },
  watch: {
    value(val){
      this.visionItems = val
    },
    "visionItems.inputStatusVision"(val) {
      this.$store.commit('brand_input_status_vision', val)
    },
  },
  mounted() {
    this.visionEvaluation = MasterData.visionEvaluation
  }
}
</script>
<style lang="scss" scoped>
.company-vision-wrap{
  .head-title{
    font-weight: 600;
  }
  .head-description{
    display: inline-block;
    font-size: 13px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.87);
  }
  .vision-evaluation{
    width: 400px;
  }
  .input-sample{
    margin-top: -3px;
  }
}
</style>
